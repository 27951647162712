import React from 'react';
import styled from 'styled-components';
import Footer from '../Footer/Footer';

const Aboutus = () => {
  return (
    <div>
      <Header>About Us</Header>
      <Div>
      <Aboutuspage>
      At Vedapurohith, we are devoted to delivering the finest puja services. Our team, Vedaangam, consists of experienced pandits based in Varanasi, India. Our goal is to offer genuine and personalized e-puja services and astrology consultations. Puja, for us, is a sacred practice that fosters a connection with our inner selves, the divine, and our community.<br/>
      We understand the challenges faced by devotees, especially those living overseas, in performing Dharmic rituals due to a lack of guidance and resources. Thus, we strive to simplify these rituals by providing virtual or physical pandits according to the devotees' needs. Through our online platform, we offer necessary guidance and connect devotees with pandits to ensure the smooth execution of their pujas.<br/>
      <center><b>Vedapurohith.com</b></center>
      Our company is dedicated to Hindu puja services, guided by a team of committed pandits and astrologers. We believe in placing puja at the heart of faith, not business. Welcome to our platform, where authentic and personalized puja and consultancy services await you.<br/>
      </Aboutuspage>
      </Div>
      <Footer />
    </div>
  )
}
const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Aboutuspage = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* top: 76px; */
`;
const Header = styled.h1`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 76px;

`;

export default Aboutus
